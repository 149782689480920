import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Layout, Cta, PortfolioGrid } from "../components";
import {
  Title,
  SectionSubtitle,
  fonts,
  Subtitle,
  Row,
  Pill,
  responsive,
  theme,
} from "theme";
import { marked } from "marked";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Information = styled.div`
  height: 100%;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 12px;
  max-width: 730px;
  font-family: ${fonts.sofiaPro};
  font-size: ${({ large }) => (large ? "20px" : "18px")};
  font-weight: 400;
  line-height: 28px;
  color: ${theme.lightGrey};
  h2 {
    font-size: 22px;
    color: ${theme.white};
  }
  h3 {
    font-size: 18px;
    color: ${theme.white};
  }
  hr {
    color: ${theme.lightPurple};
    margin: 42px 0 0 0;
    border-radius: 4px;
    width: 40px;
  }
  img {
    width: 100%;
  }
  blockquote {
    box-shadow: rgb(41, 41, 41) 3px 0px 0px 0px inset;
    margin-left: -20px;
    padding-left: 23px;
    p {
      font-style: italic;
    }
  }
  ${responsive.sm`
    width: 80%;
  `}
  ${responsive.md`
    width: 60%;
    line-height: 32px;
    font-size: 20px;
  `}
  ${responsive.lg`
    width: 50%;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
  width: 90%;
  max-width: 920px;
  margin: 32px auto;
  border-radius: 12px;
  h4 {
    color: #fff;
  }
  background-image: ${({ gradientStart, gradientEnd }) =>
    `linear-gradient(to top, #${gradientStart}, #${gradientEnd})`};
  ${responsive.md`
    width: 85%;
  `}
`;

const Portfolio = ({ data }) => {
  const { description, gradient, title, body, requirements, responsibilities } =
    data.contentfulPortfolio;

  return (
    <Layout>
      <Wrapper>
        <Container gradientStart={gradient[0]} gradientEnd={gradient[1]}>
          <SectionSubtitle>Case Study</SectionSubtitle>
          <Title bold margin="4px 0">
            {title}
          </Title>
        </Container>
        {/* <Information
          large
          dangerouslySetInnerHTML={{
            __html: marked.parse(description.description),
          }}
        /> */}
        {requirements && requirements.length > 0 && (
          <Information>
            <Subtitle bold>Roles Served</Subtitle>
            <Row gap margin="12px 0 0 0">
              {requirements.map((key, index) => (
                <Pill key={index}>
                  <p>{key}</p>
                </Pill>
              ))}
            </Row>
          </Information>
        )}
        {responsibilities && responsibilities.length > 0 && (
          <Information>
            <Subtitle bold>Responsibilities</Subtitle>
            <Row gap margin="12px 0 0 0">
              {responsibilities.map((key, index) => (
                <Pill key={index}>
                  <p>{key}</p>
                </Pill>
              ))}
            </Row>
          </Information>
        )}
        <Information
          dangerouslySetInnerHTML={{ __html: marked.parse(body.body) }}
        />
        <PortfolioGrid portfolio={data.allContentfulPortfolio.edges} />
        <Cta />
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulPortfolio(slug: { eq: $slug }) {
      ...PortfolioFragment
    }
    allContentfulPortfolio(limit: 3, filter: { slug: { ne: $slug } }) {
      edges {
        node {
          ...PortfolioFragment
        }
      }
    }
  }
`;

export default Portfolio;
